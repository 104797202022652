import {createRouter, createWebHistory} from "vue-router";
import Body from "../components/BodyComponent.vue";
import Auth from "../auth/js/index";
import {courseCategoryRoutes} from "@/router/routes/course_category";
import {courseRoutes} from "@/router/routes/course";
import {lessonRoutes} from "@/router/routes/lesson";
import {advertisementRoutes} from "@/router/routes/advertisement";
import {planRoutes} from "@/router/routes/plan";
import {studentRoutes} from "@/router/routes/student";
import {transferRoutes} from "@/router/routes/transfer";
import {notificationRoutes} from "@/router/routes/notification";
import {documentationRoutes} from "@/router/routes/documentation";
import {lessonCategoryRoutes} from "@/router/routes/lesson-category";
import {contentRoutes} from "@/router/routes/content";
import {documentationVersionRoutes} from "@/router/routes/documentation-version";
import {documentationCategoryRoutes} from "@/router/routes/documentation-category";
import {fileRoutes} from "@/router/routes/file";
import {balanceHistoryRoutes} from "@/router/routes/balance_history";
import {userRoutes} from "@/router/routes/user";
import {roleRoutes} from "@/router/routes/role";
import {chatManagerRoutes} from "@/router/routes/chat_manager";
import {optionRoutes} from "@/router/routes/option";
import {soldCourseRoutes} from "@/router/routes/sold-course";
import {leadRoutes} from "@/router/routes/lead";
import {accessPlanRoutes} from "@/router/routes/access_plan";
import {logRoutes} from "@/router/routes/log";
import {workerLogRoutes} from "@/router/routes/worker-log";
import {tokenRoutes} from "@/router/routes/token";
import {lessonViewTimeRoutes} from "@/router/routes/lesson-view-time";
import {studentReportRoutes} from "@/router/routes/student_report";


const routes = [
    // {
    //     path: "/register",
    //     name: "Register",
    //     component: () => import("../auth/register.vue"),
    //     meta: {
    //         title: " Register | Cuba - Premium Admin Template",
    //     },
    // },
    {
        path: "/auth",
        component: () => import("../auth/auth.vue"),
        children: [
            {
                path: "login",
                name: "Login Page",
                component: () => import("../auth/login.vue"),
                meta: {
                    requiresAuth: false,
                    title: "Login page"
                }
            },
            {
                path: "forgot-password",
                name: "forgot Password",
                component: () => import("../auth/forgotPassword.vue"),
                meta: {
                    title: "Forgot Password",
                    requiresAuth: false
                },
            },
        ],
    },
    {
        path: "",
        redirect: "/dashboard"
    },
    {
        path: "/",
        component: Body,
        redirect: "/dashboard",
        meta: {
            requiresAuth: true,
            permission: 'dashboard',
            title: 'Programmer UZ',
            action: 'read',
        },
        children: [
            {
                path: "/dashboard",
                name: "Dashboard",
                component: () => import("../views/dashboardPage.vue"),
                meta: {
                    requiresAuth: true,
                    permission: 'dashboard',
                    title: 'Dashboard',
                    action: 'read',
                },
            },
            {
                path: "/profile",
                name: "Profile",
                component: () => import("../views/profile.vue"),
                meta: {
                    requiresAuth: true,
                    permission: 'dashboard',
                    action: 'read',
                    title: 'Profile'
                },

            },
            courseCategoryRoutes,
            courseRoutes,
            lessonRoutes,
            planRoutes,
            optionRoutes,
            soldCourseRoutes,
            userRoutes,
            leadRoutes,
            roleRoutes,
            lessonCategoryRoutes,
            transferRoutes,
            studentRoutes,
            contentRoutes,
            advertisementRoutes,
            notificationRoutes,
            documentationRoutes,
            documentationVersionRoutes,
            documentationCategoryRoutes,
            fileRoutes,
            logRoutes,
            workerLogRoutes,
            accessPlanRoutes,
            chatManagerRoutes,
            balanceHistoryRoutes,
            tokenRoutes,
            lessonViewTimeRoutes,
            studentReportRoutes,
            {
                path: "/chat",
                name: "Chat",
                component: () => import("../views/chats/ChatComponent.vue"),
                children: [
                    {
                        path: "/chat/",
                        name: "Empty Test",
                        component: () => import("../views/chats/EmptyChat.vue"),
                        meta: {
                            requiresAuth: true,
                            permission: 'dashboard',
                            title: 'Chat'
                        },
                    },
                    {
                        path: "/chat/:id",
                        name: "Chat member",
                        component: () => import("../views/chats/SingleChat.vue"),
                        props: true,
                        meta: {
                            requiresAuth: true,
                            permission: 'dashboard',
                            title: 'Chat member'
                        },
                    },
                ],
            },
            {
                path: "/forbidden",
                name: "Access Forbidden",
                component: () => import("../views/errors/error-403-page.vue"),
            },
            {
                path: "/:pathMatch(.*)*",
                name: "Not Found",
                component: () => import("../views/errors/error-404-page.vue"),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0};
    },
});
router.beforeEach((to, from, next) => {
    if (to.meta.title) document.title = to.meta.title;
    const path = ["/auth/login", "/auth/register"];
    if (path.includes(to.path)) {
        if (Auth.isAuthenticatedUser()) {
            return next("/dashboard");
        }
    }
    if (to.meta.requiresAuth) {
        if (!Auth.isAuthenticatedUser()) {
            return next("/auth/login");
        }
        else if (!Auth.permission(to.meta)) {
            return next("/forbidden");
        }
    }
    return next();
});

export default router;
