export const advertisementRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "/advertisement",
                name: "Advertisements",
                component: () => import("@/views/advertisement/advertisement_read.vue"),
                meta: {
                    title: "Advertisement",
                    permission: "role_advertisements",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Advertisement update",
                path: "/advertisement/update/:id",
                component: () => import("@/views/advertisement/advertisement_update.vue"),
                meta: {
                    title: "Advertisement",
                    permission: "role_advertisements",
                    action: "update",
                    requiresAuth: true,
                },
            },
            {
                name: "Advertisement create",
                path: "/advertisement/create/",
                component: () => import("@/views/advertisement/advertisement_create.vue"),
                meta: {
                    title: "Advertisement",
                    permission: "role_advertisements",
                    action: "create",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    advertisementRoutes,
};
