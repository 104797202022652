export const balanceHistoryRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "/balance-history",
                name: "Balance history",
                component: () => import("@/views/balance_history/balance_history_read.vue"),
                meta: {
                    title: "Balance history",
                    permission: "role_balance_history",
                    action: "read",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    balanceHistoryRoutes,
};
