export const leadRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "lead",
                name: "Lead",
                component: () => import("@/views/leads/lead_read.vue"),
                meta: {
                    title: "Leads",
                    permission: "role_leads",
                    action: "read",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    leadRoutes,
};
