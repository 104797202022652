import ApiRequest from '@/helpers/request'
import store from '@/store'
import router from '@/router'

const state = {
    formData: [],
}

const mutations = {
    loginUser(state, payload) {
        state.formData = payload
    },
    registerUser(state, payload) {
        state.formData = payload
    },
}

const actions = {
    loginUser: (context, payload) => {
        ApiRequest.post('login', {
            form: payload.form,
        }).then((res) => {
            context.commit('loginUser', res.data)
        })
    },

    registerUser: (context, payload) => {
        ApiRequest.post('register', {
            form: payload,
        })
            .then((res) => {
                context.commit('registerUser', res.data)
                router.push('/auth/login')
                store.dispatch('setAlert', {
                    text: res.data.message,
                    status: 'success',
                })
            })
            .catch((error) => {
                // i`m used  toaseted notification replace swet alert for  register
                store.dispatch('setAlert', {
                    text: error.response.data.errors[0],
                    status: 'error',
                })
            })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
