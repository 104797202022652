export const lessonCategoryRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "lesson-category",
                name: "Lesson category",
                component: () => import("@/views/lesson_categories/lesson_category_read.vue"),
                meta: {
                    title: 'Lesson category',
                    permission: "role_lesson_categories",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Lesson category update",
                path: "/lesson-category/update/:id",
                component: () => import("@/views/lesson_categories/lesson_category_update.vue"),
                meta: {
                    title: 'Lesson category update',
                    permission: "role_lesson_categories",
                    action: "update",
                    requiresAuth: true,
                },
            },
            {
                name: "Lesson category create",
                path: "/lesson-category/create/",
                component: () => import("@/views/lesson_categories/lesson_category_create.vue"),
                meta: {
                    title: 'Lesson category crate',
                    permission: "role_lesson_categories",
                    action: "create",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    lessonCategoryRoutes,
};
