export const documentationVersionRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "documentation-version",
                name: "Documentation version",
                component: () => import("@/views/documentation/version/documentation_version_read.vue"),
                meta: {
                    title: "Documentation version",
                    permission: "role_doc_versions",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Documentation version update",
                path: "/documentation-version/update/:id",
                component: () => import("@/views/documentation/version/documentation_version_update.vue"),
                meta: {
                    title: "Documentation version update",
                    permission: "role_doc_versions",
                    action: "update",
                    requiresAuth: true,
                }
            },
            {
                name: "Documentation version create",
                path: "/documentation-version/create/",
                component: () => import("@/views/documentation/version/documentation_version_create.vue"),
                meta: {
                    title: "Documentation version create",
                    permission: "role_doc_versions",
                    action: "create",
                    requiresAuth: true,
                }
            },
        ],
    };

export default {
    documentationVersionRoutes,
};
