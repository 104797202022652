export const chatManagerRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "/chat-manager",
                name: "Managers",
                component: () => import("@/views/chat_managers/chat_manager_read.vue"),
                meta: {
                    title: "Managers",
                    permission: "role_chat_managers",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Manager update",
                path: "/chat-manager/update/:id",
                component: () => import("@/views/chat_managers/chat_manager_update.vue"),
                meta: {
                    title: "Chat managers",
                    permission: "role_chat_managers",
                    action: "update",
                    requiresAuth: true,
                },
            },
            {
                name: "Manager create",
                path: "/chat-manager/create/",
                component: () => import("@/views/chat_managers/chat_manager_create.vue"),
                meta: {
                    title: "Chat Managers",
                    permission: "role_chat_managers",
                    action: "create",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    chatManagerRoutes,
};
