export const planRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "/plan",
                name: "Plans",
                component: () => import("@/views/plan/plan_read.vue"),
                meta: {
                    title: 'Plan',
                    permission: "role_plans",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Plan update",
                path: "/plan/update/:id",
                component: () => import("@/views/plan/plan_update.vue"),
                meta: {
                    title: 'Plan update',
                    permission: "role_plans",
                    action: "update",
                    requiresAuth: true,
                },
            },
            {
                name: "Subscribers",
                path: "/plan/show/:id",
                component: () => import("@/views/plan/plan_stats.vue"),
                meta: {
                    title: 'Subscribers',
                    permission: "role_plans",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Plan create",
                path: "/plan/create/",
                component: () => import("@/views/plan/plan_create.vue"),
                meta: {
                    title: 'Plan create',
                    permission: "role_plans",
                    action: "create",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    planRoutes,
};
