export const optionRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "/option",
                name: "Options",
                component: () => import("@/views/option/option_read.vue"),
                meta: {
                    title: 'Options',
                    permission: "role_settings",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Option update",
                path: "/option/update/:id",
                component: () => import("@/views/option/option_update.vue"),
                meta: {
                    title: 'Option update',
                    permission: "role_settings",
                    action: "update",
                    requiresAuth: true,
                },
            },
            {
                name: "Option create",
                path: "/option/create/",
                component: () => import("@/views/option/option_create.vue"),
                meta: {
                    title: 'Option create',
                    permission: "role_settings",
                    action: "create",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    optionRoutes,
};
