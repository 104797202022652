import axios from 'axios'
import Swal from 'sweetalert2'
import store from '@/store/index'
import { StateStatus } from '@/helpers/state_status'
import Auth from '@/auth/js/index'

class ApiRequest {
    token = null
    endPoint = process.env.VUE_APP_URL + '/'
    state = StateStatus.normal

    isLoading() {
        return store.getters.getState === StateStatus.loading
    }
    filterUrl(url) {
        if (url[0] === '/') {
            url = url.substring(1)
        }
        return url
    }
    getToken() {
        return store.getters.getUserToken
    }
    getHeader() {
        let token = this.token ?? this.getToken()
        return {
            Authorization: 'Bearer ' + token,
            'Access-Control-Allow-Origin': '*',
        }
    }
    get(url) {
        let headers = this.getHeader()
        url = this.endPoint + this.filterUrl(url)
        return new Promise(function (resolve, reject) {
            axios
                .get(url, { headers: headers })
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    console.log(error)
                    sweet(error)
                    reject(error)
                })
        })
    }

    post(url, data = {}) {
        let headers = this.getHeader()
        url = this.endPoint + this.filterUrl(url)
        return new Promise(function (resolve, reject) {
            axios
                .post(url, data.form, { headers })
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    sweet(error)
                    reject(error)
                })
        })
    }

    putData(url, data = {}) {
        let headers = this.getHeader()
        url = this.endPoint + this.filterUrl(url)
        return new Promise(function (resolve, reject) {
            axios
                .put(url, data.form, { headers: headers })
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    sweet(error)
                    reject(error)
                })
        })
    }

    put(url, data = {}) {
        let headers = this.getHeader()
        url = this.endPoint + this.filterUrl(url)
        let formData = data.form
        formData.append('_method', 'PUT')
        return new Promise(function (resolve, reject) {
            axios
                .post(url, formData, { headers: headers })
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    sweet(error)
                    reject(error)
                })
        })
    }
    delete(url) {
        let headers = this.getHeader()
        url = this.endPoint + this.filterUrl(url)
        return new Promise(function (resolve, reject) {
            axios
                .delete(url, { headers: headers })
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    sweet(error)
                    reject(error)
                })
        })
    }
}

function sweet(error) {
    let status = error.response ? error.response.status  : ''
    if (status === 401) {
        Auth.logOut()
        return
    }
    let text = error.response ? error.response.data.errors[0] : error.message
    Swal.fire({
        title: 'Nimadir xato ketdi:',
        text: text ,
        icon: 'error',
    })
}

export default new ApiRequest()
