import {createStore} from 'vuex'
import layout from './modules/layout'
import menu from './modules/menu'
import getResponse from "@/store/modules/getResponse";
import auth from "@/store/modules/auth";
import chat from "@/store/modules/chat";

export default createStore({
    state: {
        rightToggle: true,
        token: localStorage.getItem('_auth_token'),
        message: {},
        sidebarUnfoldable: false,
        permission: localStorage.getItem("_configuration") ? JSON.parse(atob(localStorage.getItem("_configuration"))) : '',
        formData: JSON.parse(localStorage.getItem('userinfo')),
    },
    getters: {
        getUserCan(state) {
            return state.permission
        },
        getUserToken(state) {
            return state.token
        },
        getMessageData(state) {
            return state.message
        },
        getLessonsToggle(state) {
            return state.lessonsToggle
        },
        getRightToggle: (state) => state.rightToggle,
        getFormData(state) {
            return state.formData
        }
    },
    mutations: {
        toggleSidebar(state) {
            state.sidebarVisible = !state.sidebarVisible
        },
        toggleUnfoldable(state) {
            state.sidebarUnfoldable = !state.sidebarUnfoldable
        },
        updateSidebarVisible(state, payload) {
            state.sidebarVisible = payload.value
        },
        setLessonsToggle(state, payload) {
            state.lessonsToggle = payload
        },
        setToken(state, payload) {
            state.token = payload
            localStorage.setItem('_auth_token', payload)
        },
        setPermission(state, payload) {
            let per = {
                role_users: {
                    read: payload.role_users > 0,
                    create: payload.role_users > 1,
                    update: payload.role_users > 2,
                    delete: payload.role_users > 3
                },
                role_courses: {
                    read: payload.role_courses > 0,
                    create: payload.role_courses > 1,
                    update: payload.role_courses > 2,
                    delete: payload.role_courses > 3
                },
                role_contents: {
                    read: payload.role_contents > 0,
                    create: payload.role_contents > 1,
                    update: payload.role_contents > 2,
                    delete: payload.role_contents > 3
                },
                role_sold_courses: {
                    read: payload.role_sold_courses > 0,
                    create: payload.role_sold_courses > 1,
                    update: payload.role_sold_courses > 2,
                    delete: payload.role_sold_courses > 3
                },
                role_categories: {
                    read: payload.role_categories > 0,
                    create: payload.role_categories > 1,
                    update: payload.role_categories > 2,
                    delete: payload.role_categories > 3
                },
                role_lessons: {
                    read: payload.role_lessons > 0,
                    create: payload.role_lessons > 1,
                    update: payload.role_lessons > 2,
                    delete: payload.role_lessons > 3
                },
                role_files: {
                    read: payload.role_files > 0,
                    create: payload.role_files > 1,
                    update: payload.role_files > 2,
                    delete: payload.role_files > 3
                },
                role_groups: {
                    read: payload.role_groups > 0,
                    create: payload.role_groups > 1,
                    update: payload.role_groups > 2,
                    delete: payload.role_groups > 3
                },
                role_post_categories: {
                    read: payload.role_post_categories > 0,
                    create: payload.role_post_categories > 1,
                    update: payload.role_post_categories > 2,
                    delete: payload.role_post_categories > 3
                },
                role_posts: {
                    read: payload.role_posts > 0,
                    create: payload.role_posts > 1,
                    update: payload.role_posts > 2,
                    delete: payload.role_posts > 3
                },
                role_notifications: {
                    read: payload.role_notifications > 0,
                    create: payload.role_notifications > 1,
                    update: payload.role_notifications > 2,
                    delete: payload.role_notifications > 3
                },
                role_settings: {
                    read: payload.role_settings > 0,
                    create: payload.role_settings > 1,
                    update: payload.role_settings > 2,
                    delete: payload.role_settings > 3
                },
                role_roles: {
                    read: payload.role_roles > 0,
                    create: payload.role_roles > 1,
                    update: payload.role_roles > 2,
                    delete: payload.role_roles > 3
                },
                role_balance_history: {
                    read: payload.role_balance_history > 0,
                    create: payload.role_balance_history > 1,
                    update: payload.role_balance_history > 2,
                    delete: payload.role_balance_history > 3
                },
                role_access_plans: {
                    read: payload.role_access_plans > 0,
                    create: payload.role_access_plans > 1,
                    update: payload.role_access_plans > 2,
                    delete: payload.role_access_plans > 3
                },
                role_advertisements: {
                    read: payload.role_advertisements > 0,
                    create: payload.role_advertisements > 1,
                    update: payload.role_advertisements > 2,
                    delete: payload.role_advertisements > 3
                },
                role_api_tokens: {
                    read: payload.role_api_tokens > 0,
                    create: payload.role_api_tokens > 1,
                    update: payload.role_api_tokens > 2,
                    delete: payload.role_api_tokens > 3
                },
                role_books: {
                    read: payload.role_books > 0,
                    create: payload.role_books > 1,
                    update: payload.role_books > 2,
                    delete: payload.role_books > 3
                },
                role_book_categories: {
                    read: payload.role_book_categories > 0,
                    create: payload.role_book_categories > 1,
                    update: payload.role_book_categories > 2,
                    delete: payload.role_book_categories > 3
                },
                role_chats: {
                    read: payload.role_chats > 0,
                    create: payload.role_chats > 1,
                    update: payload.role_chats > 2,
                    delete: payload.role_chats > 3
                },
                role_chat_managers: {
                    read: payload.role_chat_managers > 0,
                    create: payload.role_chat_managers > 1,
                    update: payload.role_chat_managers > 2,
                    delete: payload.role_chat_managers > 3
                },
                role_course_categories: {
                    read: payload.role_course_categories > 0,
                    create: payload.role_course_categories > 1,
                    update: payload.role_course_categories > 2,
                    delete: payload.role_course_categories > 3
                },
                role_documentations: {
                    read: payload.role_documentations > 0,
                    create: payload.role_documentations > 1,
                    update: payload.role_documentations > 2,
                    delete: payload.role_documentations > 3
                },
                role_doc_categories: {
                    read: payload.role_doc_categories > 0,
                    create: payload.role_doc_categories > 1,
                    update: payload.role_doc_categories > 2,
                    delete: payload.role_doc_categories > 3
                },
                role_doc_versions: {
                    read: payload.role_doc_versions > 0,
                    create: payload.role_doc_versions > 1,
                    update: payload.role_doc_versions > 2,
                    delete: payload.role_doc_versions > 3
                },
                role_enrollments: {
                    read: payload.role_enrollments > 0,
                    create: payload.role_enrollments > 1,
                    update: payload.role_enrollments > 2,
                    delete: payload.role_enrollments > 3
                },
                role_enrollment_lessons: {
                    read: payload.role_enrollment_lessons > 0,
                    create: payload.role_enrollment_lessons > 1,
                    update: payload.role_enrollment_lessons > 2,
                    delete: payload.role_enrollment_lessons > 3
                },
                role_leads: {
                    read: payload.role_leads > 0,
                    create: payload.role_leads > 1,
                    update: payload.role_leads > 2,
                    delete: payload.role_leads > 3
                },
                role_lead_books: {
                    read: payload.role_lead_books > 0,
                    create: payload.role_lead_books > 1,
                    update: payload.role_lead_books > 2,
                    delete: payload.role_lead_books > 3
                },
                role_lesson_categories: {
                    read: payload.role_lesson_categories > 0,
                    create: payload.role_lesson_categories > 1,
                    update: payload.role_lesson_categories > 2,
                    delete: payload.role_lesson_categories > 3
                },
                role_options: {
                    read: payload.role_options > 0,
                    create: payload.role_options > 1,
                    update: payload.role_options > 2,
                    delete: payload.role_options > 3
                },
                role_logs: {
                    read: payload.role_logs > 0,
                    create: payload.role_logs > 1,
                    update: payload.role_logs > 2,
                    delete: payload.role_logs > 3
                },
                role_payme_cards: {
                    read: payload.role_payme_cards > 0,
                    create: payload.role_payme_cards > 1,
                    update: payload.role_payme_cards > 2,
                    delete: payload.role_payme_cards > 3
                },
                role_payme_transfers: {
                    read: payload.role_payme_transfers > 0,
                    create: payload.role_payme_transfers > 1,
                    update: payload.role_payme_transfers > 2,
                    delete: payload.role_payme_transfers > 3
                },
                role_plans: {
                    read: payload.role_plans > 0,
                    create: payload.role_plans > 1,
                    update: payload.role_plans > 2,
                    delete: payload.role_plans > 3
                },
                role_portfolios: {
                    read: payload.role_portfolios > 0,
                    create: payload.role_portfolios > 1,
                    update: payload.role_portfolios > 2,
                    delete: payload.role_portfolios > 3
                },
                role_problems: {
                    read: payload.role_problems > 0,
                    create: payload.role_problems > 1,
                    update: payload.role_problems > 2,
                    delete: payload.role_problems > 3
                },
                role_problem_answers: {
                    read: payload.role_problem_answers > 0,
                    create: payload.role_problem_answers > 1,
                    update: payload.role_problem_answers > 2,
                    delete: payload.role_problem_answers > 3
                },
                role_sold_categories: {
                    read: payload.role_sold_categories > 0,
                    create: payload.role_sold_categories > 1,
                    update: payload.role_sold_categories > 2,
                    delete: payload.role_sold_categories > 3
                },
                role_students: {
                    read: payload.role_students > 0,
                    create: payload.role_students > 1,
                    update: payload.role_students > 2,
                    delete: payload.role_students > 3
                },
                role_video_files: {
                    read: payload.role_video_files > 0,
                    create: payload.role_video_files > 1,
                    update: payload.role_video_files > 2,
                    delete: payload.role_video_files > 3
                }
            };
            let hash = btoa((JSON.stringify(per)))
            state.permission = per
            localStorage.setItem("_configuration", hash);
        },
        setMessage(state, payload) {
            state.message.text = payload.text
            state.message.status = payload.status
        },
        setRightToggle: (state) => {
            state.rightToggle = !state.rightToggle
        },
        setFormData(state, payload) {
            state.formData = payload
            localStorage.setItem('userinfo', JSON.stringify(payload))
        }
    },
    actions: {
        setAlert(context, payload) {
            context.commit('setMessage', payload)
        },
    },
    modules: {
        layout,
        menu,
        getResponse,
        auth,
        chat
    }
});

