<template>
  <div>
    <!-- page-wrapper Start-->
    <div class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div class="login-main login-form-card">
                <div>
                  <div>
                    <form
                      class="theme-form"
                      @submit.prevent="loginFunc"
                    >
                      <h4>Tizimga kirish</h4>
                      <div class="form-group">
                        <label class="col-form-label">Email Address</label>
                        <input
                          v-model="form.email"
                          class="form-control"
                          type="email"
                          required=""
                          placeholder="something@example.com"
                          :class="{
                            'is-invalid': submitted && !form.email,
                          }"
                        >
                        <div
                          v-show="submitted && !form.email"
                          class="invalid-feedback"
                        >
                          Email is required
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-form-label">Password</label>
                        <input
                          v-model="form.password"
                          autocomplete=""
                          class="form-control"
                          :type="type"
                          required=""
                          placeholder="*********"
                          :class="{
                            'is-invalid': submitted && !form.email,
                          }"
                        >
                        <div
                          v-show="submitted && !form.password"
                          class="invalid-feedback"
                        >
                          Email is required
                        </div>
                        <div
                          class="show-hide"
                          @click="showPassword"
                        >
                          <span class="show" />
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="checkbox p-0">
                          <input
                            id="checkbox1"
                            type="checkbox"
                          >
                        </div>
                        <button
                          class="btn btn-primary btn-block w-100"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>

                      <!--                      <p class="mt-4 mb-0">-->
                      <!--                        Don't have account?-->
                      <!--                        <router-link-->
                      <!--                          class="ml-2"-->
                      <!--                          to="/register"-->
                      <!--                        >-->
                      <!--                          Create Account-->
                      <!--                        </router-link>-->
                      <!--                      </p>-->
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- latest jquery-->
  </div>
</template>

<script>
import axios from "axios";
import Auth from "@/auth/js/index";

export default {
  name: "LoginPage",
  data() {
    return {
      form: {
        email: null,
        password: null,
        // email: 'admin@gmail.com',
        // password: 'admin123',
      },
      type: "password",
      submitted: false,
    };
  },
computed:{
  userCan(){
    return  this.$store.getters["getUserCan"]
  }
},
  methods: {
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },

    loginFunc() {
      this.isLoading = true;
      axios
          .post(process.env.VUE_APP_URL + "/login", this.form)
          .then((res) => {
            let result = res.data;
            this.$store.commit("setToken", result.authorization.token);
            this.$store.commit("setPermission", result.role);
            Auth.localAuthLogin({
              authorization: result.authorization,
              user: {
                name: result.user.name,
                surname: result.user.surname,
                email: result.user.email,
                role: result.role.name,
                is_manager: result.is_manager
              },
            });
            this.isLoading = false;
                return this.$router.push({path:'/dashboard'});
          })
          .catch((error) => {
            // console.log(error)
            this.isLoading = false;
            this.$store.dispatch("setAlert", {
              text: error.response.data.errors[0],
              status: "error",
            });
          });
    },
  },
};
</script>

<style></style>
