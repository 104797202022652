<template>
  <router-view />
</template>

<script>
import Swal from "sweetalert2";

export default {
  computed: {
    text() {
      return this.message.text;
    },

    message() {
      return this.$store.getters.getMessageData;
    },
  },
  watch: {
    text(newText, oldText) {
      if (
        newText !== oldText &&
        newText !== undefined &&
        this.text !== undefined
      ) {
        let status = this.message.status;

        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: status,
          title: newText,
        });
      }
      this.$store.commit("setMessage", {});
    },
  },
};
</script>
<style lang="scss">
.card-footer .pagination .pagination-item span {
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
}
.card-footer .pagination .pagination-item:hover span {
  color: #fff;
  cursor: pointer;
  background: #4d4d4d;
}
.page-active {
  color: #fff !important;
  background: #4d4d4d;
}
.hover-list:hover {
  background-color: #ccccc4;
  cursor: row-resize;
}
.cursor:hover {
  background-color: #eee;
  cursor: pointer;
}
.hover-icon {
  margin: 0 5px;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 50px;
}
.hover-icon:hover {
  animation: spin 2s infinite linear;
}
.hover-list:hover .hover-icon {
  cursor: pointer;
  background-color: #ffffff;
}
.action-icon {
  border-radius: 5px;
  color: #bbb;
  font-size: 18px;
}
.active-pagination {
  background: var(--primary);
  color: var(--white);
  height: 100%;
}
.action-icon i {
  padding: 8px;
}
.action-icon:hover {
  background-color: #bbb;
  color: #fff;
}
.pagination a:hover:not(.active) {background-color: #ddd;}
</style>
