export const studentRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "student",
                name: "Students",
                component: () => import("@/views/student/student_read.vue"),
                meta: {
                    title: 'Student',
                    permission: "role_students",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Student update",
                path: "/student/update/:id",
                component: () => import("@/views/student/student_update.vue"),
                meta: {
                    title: 'Student update',
                    permission: "role_students",
                    action: "update",
                    requiresAuth: true,
                },
            },
            {
                name: "student show",
                path: "/student/show/:id",
                component: () => import("@/views/student/student_show.vue"),
                meta: {
                    title: 'Student show',
                    permission: "role_students",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "student create",
                path: "/student/create/",
                component: () => import("@/views/student/student_create.vue"),
                meta: {
                    title: 'Student create',
                    permission: "role_students",
                    action: "create",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    studentRoutes,
};
