export const contentRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "content",
                name: "Contents",
                component: () => import("@/views/content/content_read.vue"),
                meta: {
                    title: "Content",
                    permission: "role_contents",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Content update",
                path: "/content/update/:id",
                component: () => import("@/views/content/content_update.vue"),
                meta: {
                    title: "Content update",
                    permission: "role_contents",
                    action: "update",
                    requiresAuth: true,
                }
            },
            {
                name: "Content create",
                path: "/content/create/",
                component: () => import("@/views/content/content_create.vue"),
                meta: {
                    title: "Content create",
                    permission: "role_contents",
                    action: "create",
                    requiresAuth: true,
                }
            },
        ],
    };

export default {
    contentRoutes,
};
