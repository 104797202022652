export const tokenRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                name: "Api token",
                path: "api-token",
                component: () => import("@/views/tokens/token_read.vue"),
                meta: {
                    title: "Api token",
                    permission: "role_settings",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Api token update",
                path: "/api-token/update/:id",
                component: () => import("@/views/tokens/token_update.vue"),
                meta: {
                    title: "Api token",
                    permission: "role_settings",
                    action: "update",
                    requiresAuth: true,
                }
            },
            {
                name: "Api token create",
                path: "/api-token/create/",
                component: () => import("@/views/tokens/token_create.vue"),
                meta: {
                    title: "Api token",
                    permission: "role_settings",
                    action: "create",
                    requiresAuth: true,
                }
            },
        ],
    };

export default {
    tokenRoutes,
};
