import EventEmitter from "events";
import Token from "@/helpers/token";
import router from "@/router";
const Userinfo = "userinfo";
const localStorageKey = "loggedIn";
const userLogin = "_auth_token";
import store from "@/store";
import login from "@/auth/login.vue";
class Auth extends EventEmitter {
  authToken = null;
  userProfile = null;
  tokenExpiry = null;
  localAuthLogin(authResult) {
    this.authToken = authResult.authorization.token;
    this.userProfile = authResult.user;
    localStorage.setItem(localStorageKey, "true");
    localStorage.setItem(userLogin, this.authToken);
    localStorage.setItem(
      Userinfo,
      JSON.stringify({
        displayName: this.userProfile.name + ' ' + this.userProfile.surname,
        email: this.userProfile.email,
        role: this.userProfile.role,
        is_manager: this.userProfile.is_manager
      })
    );
  }
  logOut() {
    localStorage.removeItem(Userinfo);
    localStorage.removeItem(userLogin);
    localStorage.removeItem(localStorageKey);
    this.authToken = null;
    this.tokenExpiry = null;
    router.push("/auth/login");
  }
  isAuthenticatedUser() {
    return (
      localStorage.getItem(localStorageKey) === "true" &&
      Token.isValid(localStorage.getItem(userLogin))
    );
  }
  permission(meta) {
    let userCan = store.getters.getUserCan
    try {
      if (meta.permission === 'dashboard') {
        return true
      } else if (userCan[meta.permission][meta.action]) {
        return true
      }
      return false
    } catch (error) {
      // console.log(error)
    }

  }
}

export default new Auth();
