export const documentationCategoryRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "documentation-category",
                name: "Documentation category",
                component: () => import("@/views/documentation/category/documentation_category_read.vue"),
                meta: {
                    title: "Documentation categories",
                    permission: "role_doc_categories",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Documentation category update",
                path: "/documentation-category/update/:id",
                component: () => import("@/views/documentation/category/documentation_category_update.vue"),
                meta: {
                    title: "Documentation categories update",
                    permission: "role_doc_categories",
                    action: "update",
                    requiresAuth: true,
                },
            },
            {
                name: "Documentation category create",
                path: "/documentation-category/create/",
                component: () => import("@/views/documentation/category/documentation_category_create.vue"),
                meta: {
                    title: "Documentation categories create",
                    permission: "role_doc_categories",
                    action: "create",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    documentationCategoryRoutes,
};
