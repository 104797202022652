export const courseRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "course",
                name: "Courses",
                component: () => import("@/views/course/course_read.vue"),
                meta: {
                    title: "Course",
                    permission: "role_courses",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "course update",
                path: "/course/update/:id",
                component: () => import("@/views/course/course_update.vue"),
                meta: {
                    title: "Course update",
                    permission: "role_courses",
                    action: "update",
                    requiresAuth: true,
                }
            },
            {
                name: "course create",
                path: "/course/create/",
                component: () => import("@/views/course/course_create.vue"),
                meta: {
                    title: "Course Create",
                    permission: "role_courses",
                    action: "create",
                    requiresAuth: true,
                }
            },
            {
                name: "Course Join Category",
                path: "/course/:id",
                component: () => import("@/views/course/lesson_category_join_course.vue"),
                meta: {
                    title: "Course join category",
                    permission: "role_courses",
                    action: "read",
                    requiresAuth: true,
                }
            },
        ],
    };

export default {
    courseRoutes,
};
