export const soldCourseRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "/sold-course",
                name: "Sold Courses",
                component: () => import("@/views/sold_courses/sold_course_read.vue"),
                meta: {
                    title: 'Sold Course',
                    permission: "role_sold_courses",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Sold Course update",
                path: "/sold-course/update/:id",
                component: () => import("@/views/sold_courses/sold_course_update.vue"),
                meta: {
                    title: 'Sold Course update',
                    permission: "role_sold_courses",
                    action: "update",
                    requiresAuth: true,
                },
            },
            {
                name: "Sold Course create",
                path: "/sold-course/create/",
                component: () => import("@/views/sold_courses/sold_course_create.vue"),
                meta: {
                    title: 'Sold Course create',
                    permission: "role_sold_courses",
                    action: "create",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    soldCourseRoutes,
};
