export const workerLogRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "worker-log",
                name: "Worker Log",
                component: () => import("@/views/logs/worker_log_read.vue"),
                meta: {
                    title: 'Worker Logs',
                    permission: "role_logs",
                    action: "read",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    workerLogRoutes,
};
