import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/scss/app.scss";
import VueFeather from "vue-feather";
import Pxcard from "@/components/Pxcard.vue";
import breadCrumbs from "@/components/bread_crumbs.vue";
import "highlight.js/styles/github-dark-dimmed.css";
import VueApexCharts from 'vue-apexcharts';
import "highlight.js/lib/common";
import hljsVuePlugin from "@highlightjs/vue-plugin";
import { DatePicker } from "ant-design-vue";
import "./styles/categories.scss";
import VueSweetalert2 from "vue-sweetalert2";
const app = createApp(App);
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
app.component("BreadCrumbs", breadCrumbs);
app.use(hljsVuePlugin);
app.use(VuePlyr);
app.use(VueSweetalert2);
app.component(Pxcard.name, Pxcard);
app.component(VueFeather.name, VueFeather);
app.component('ApexChart', VueApexCharts);

window.Pusher = require('pusher-js')
const Echo = require('laravel-echo').default;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'local',
    wsHost: 'api.programmer.uz',
    wsPort: 8523,
    wssPort: 8523,
    encrypted: true,
    forceTLS: true,
    disableStats: true,
    cluster: 'mt1',
    enabledTransports: ['ws','wss'],
});
app.use(require('vue-chartist'));
app.use(store);
app.use(router);
app.use(DatePicker);
app.mount("#app");
