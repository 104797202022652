export const logRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "log",
                name: "Log",
                component: () => import("@/views/logs/log_read.vue"),
                meta: {
                    title: 'Logs',
                    permission: "role_logs",
                    action: "read",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    logRoutes,
};
