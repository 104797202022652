export const lessonViewTimeRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "/lesson-view-time",
                name: "Lesson View Time",
                component: () => import("@/views/lesson_view_time/view_read.vue"),
                meta: {
                    title: 'Lesson View Time',
                    permission: "role_lessons",
                    action: "read",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    lessonViewTimeRoutes,
};
