export const userRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "/user",
                name: "Users",
                component: () => import("@/views/user/user_read.vue"),
                meta: {
                    title: 'Users',
                    permission: "role_users",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "User update",
                path: "/user/update/:id",
                component: () => import("@/views/user/user_update.vue"),
                meta: {
                    title: 'User update',
                    permission: "role_users",
                    action: "update",
                    requiresAuth: true,
                },
            },
            {
                name: "User create",
                path: "/user/create/",
                component: () => import("@/views/user/user_create.vue"),
                meta: {
                    title: 'User create',
                    permission: "role_users",
                    action: "create",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    userRoutes,
};
