export const lessonRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "lesson",
                name: "Lessons",
                component: () => import("@/views/lessons/lesson_read.vue"),
                meta: {
                    title: "Lesson",
                    permission: "role_lessons",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "lesson update",
                path: "/lesson/update/:id",
                component: () => import("@/views/lessons/lesson_update.vue"),
                meta: {
                    title: 'Lesson Update',
                    permission: "role_lessons",
                    action: "update",
                    requiresAuth: true,
                }
            },
            {
                name: "lesson show",
                path: "/lesson/show/:id",
                component: () => import("@/views/lessons/lesson_show.vue"),
                meta: {
                    title: 'Lesson Show',
                    permission: "role_lessons",
                    action: "read",
                    requiresAuth: true,
                }
            },
            {
                name: "lesson create",
                path: "/lesson/create/",
                component: () => import("@/views/lessons/lesson_create.vue"),
                meta: {
                    title: 'Lesson Create',
                    permission: "role_lessons",
                    action: "create",
                    requiresAuth: true,
                }
            },
        ],
    };

export default {
    lessonRoutes,
};
