export const fileRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "video-file",
                name: "Files",
                component: () => import("@/views/files/file_read.vue"),
                meta: {
                    title: "Files",
                    permission: "role_files",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "file update",
                path: "/video-file/update/:id",
                component: () => import("@/views/files/file_update.vue"),
                meta: {
                    title: "File update",
                    permission: "role_files",
                    action: "update",
                    requiresAuth: true,
                }
            },
            {
                name: "file create",
                path: "/video-file/create",
                component: () => import("@/views/files/file_create.vue"),
                meta: {
                    title: "File create",
                    permission: "role_files",
                    action: "create",
                    requiresAuth: true,
                }
            },
        ],
    };

export default {
    fileRoutes,
};
