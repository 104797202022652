export const transferRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "/transfer",
                name: "Transfers",
                component: () => import("@/views/transfer/transfer_read.vue"),
                meta: {
                    title: 'Transfer',
                    permission: "role_payme_transfers",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "transfer show",
                path: "/transfer/show/:id",
                component: () => import("@/views/transfer/transfer_show.vue"),
                meta: {
                    title: 'Transfer show',
                    permission: "role_payme_transfers",
                    action: "read",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    transferRoutes,
};
