export const documentationRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "documentation",
                name: "Documentations",
                component: () => import("@/views/documentation/documentation_read.vue"),
                meta: {
                    title: "Documentation",
                    permission: "role_documentations",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "documentation update",
                path: "/documentation/update/:id",
                component: () => import("@/views/documentation/documentation_update.vue"),
                meta: {
                    title: "Documentation update",
                    permission: "role_documentations",
                    action: "update",
                    requiresAuth: true,
                },

            },
            {
                name: "documentation create",
                path: "/documentation/create/",
                component: () => import("@/views/documentation/documentation_create.vue"),
                meta: {
                    title: "Documentation create",
                    permission: "role_documentations",
                    action: "create",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    documentationRoutes,
};
