export const accessPlanRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                name: "Access plan",
                path: "access-plan",
                component: () => import("@/views/access_plan/access_plan_read.vue"),
                meta: {
                    title: "Access plan",
                    permission: "role_access_plans",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Access plan update",
                path: "/access-plan/update/:id",
                component: () => import("@/views/access_plan/access_plan_update.vue"),
                meta: {
                    title: "Access plan",
                    permission: "role_access_plans",
                    action: "update",
                    requiresAuth: true,
                }
            },
            {
                name: "Access plan create",
                path: "/access-plan/create/",
                component: () => import("@/views/access_plan/access_plan_create.vue"),
                meta: {
                    title: "Access plan",
                    permission: "role_access_plans",
                    action: "create",
                    requiresAuth: true,
                }
            },
        ],
    };

export default {
    accessPlanRoutes,
};
