export const notificationRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "notification",
                name: "Notifications",
                component: () => import("@/views/notification/notification_read.vue"),
                meta: {
                    title: 'Notifications',
                    permission: "role_notifications",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Notification update",
                path: "/notification/update/:id",
                component: () => import("@/views/notification/notification_update.vue"),
                meta: {
                    title: 'Notification update',
                    permission: "role_notifications",
                    action: "update",
                    requiresAuth: true,
                },
            },
            {
                name: "Notification show",
                path: "/notification/show/:id",
                component: () => import("@/views/notification/notification_show.vue"),
                meta: {
                    title: 'Notification show',
                    permission: "role_notifications",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Notification send",
                path: "/notification/send/:id",
                component: () => import("@/views/notification/notification_send.vue"),
                meta: {
                    title: 'Notification send',
                    permission: "role_notifications",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Notification create",
                path: "/notification/create/",
                component: () => import("@/views/notification/notification_create.vue"),
                meta: {
                    title: 'Notification create',
                    permission: "role_notifications",
                    action: "create",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    notificationRoutes,
};
