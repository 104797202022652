export const roleRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: "/role",
                name: "Roles",
                component: () => import("@/views/role/role_read.vue"),
                meta: {
                    title: 'Roles',
                    permission: "role_roles",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Role update",
                path: "/role/update/:id",
                component: () => import("@/views/role/role_update.vue"),
                meta: {
                    title: 'Role update',
                    permission: "role_roles",
                    action: "update",
                    requiresAuth: true,
                }
            },
            {
                name: "Role create",
                path: "/role/create/",
                component: () => import("@/views/role/role_create.vue"),
                meta: {
                    title: 'Role create',
                    permission: "role_roles",
                    action: "create",
                    requiresAuth: true,
                }
            },
        ],
    };

export default {
    roleRoutes,
};
