import ApiRequest from "@/helpers/request";
const state = {
  items: [],
  item: {},
  meta: {},
  data: {},
  columns: [],
  loading: true,
  infoLoader: true,
};
const getters = {
  getItems(state) {
    return state.items
  },
  getMeta(state) {
    return state.meta;
  },
  getItem(state) {
    return state.item;
  },
  getData(state) {
    return state.data;
  },
  getColumns(state) {
    return state.columns;
  },
  getLoading(state) {
    return state.loading;
  },
  getInfoLoader(state) {
    return state.infoLoader;
  },
};
// mutations
const mutations = {
  setItems: (state, payload) => {
    state.items = payload
  },
  setLoad: (state, payload) => {
    state.loading = payload
  },
  setInfoLoader: (state, payload) => {
    state.infoLoader = payload
  },
  setColumns: (state, payload) => {
    state.columns = payload
  },
  setItem: (state, payload) => {
    state.item = payload
  },
  setMeta: (state, payload) => {
    state.meta = payload
  },
  setData: (state, payload) => {
    state.data = payload
  },
  deleteItem: (state, payload) => {
    for (let i = 0; i < state.items.length; i++) {
      if (state.items[i].values.id === payload) {
        state.items.splice(i, 1);
        break;
      }
    }
  },
  deleteItemInItems: (state, payload) => {
    for (let i = 0; i < state.items.length; i++) {
      if (state.items[i].id === payload) {
        state.items.splice(i, 1);
        break;
      }
    }
  },
};

// actions
const actions = {
  getData: (context, payload) => {
    context.commit("setItems", []);
    ApiRequest.get(payload).then((res) => {
      let data = res.data.data;
      let meta = res.data.meta;
      if (data.length > 0) {
        let obj = Object.values(data);
        let rows = [];
        let columns = Object.keys(data[0]);
        for (let i = 0; i < obj.length; i++) {
          rows.push({ key: Object.keys(obj[i]), values: obj[i] });
        }
        context.commit("setColumns", columns);
        context.commit("setItems", Object.assign(rows));
      }
      if (meta) {
        context.commit("setMeta", meta);
      } else {
        context.commit("setMeta", {});
      }
      context.commit("setLoad", false);
    });
  },
  setLoader: (context, payload) => {
    context.commit("setLoad", payload);
  },
  deleteItem: (context, payload) => {
    ApiRequest.delete(payload.url + "/" + payload.id).then((data) => {
      context.commit(
        "setMessage",
        {
          text: data.data.message,
          status: "success",
        },
        { root: true }
      );
      context.commit("deleteItem", payload.id);
    });
  },
  deleteItemInItems: (context, payload) => {
    ApiRequest.delete(payload.url + "/" + payload.id)
        .then((data) => {
          context.commit(
              "setMessage",
              {
                text: data.data.message,
                status: "success",
              },
              { root: true }
          );
        context.commit("deleteItemInItems", payload.id);
    });
  },
  createData: (context, payload) => {
    ApiRequest.post(payload.url, { form: payload.form }).then((data) => {
      context.commit(
        "setMessage",
        {
          text: data.data.message,
          status: "success",
        },
        { root: true }
      );
    }).catch((e)=>{
      console.log(e)
    })
  },
  updateData: (context, payload) => {
    ApiRequest.put(payload.url, { form: payload.form }).then((data) => {
      context.commit(
        "setMessage",
        {
          text: data.data.message,
          status: "success",
        },
        { root: true }
      );
    })
  },
  showItem: (context, payload) => {
    context.commit("setInfoLoader", true);
    ApiRequest.get(payload).then((res) => {
      let data = res.data.data;
      context.commit("setData", data);
      context.commit("setLoad", false);
      context.commit("setInfoLoader", false);
    });
  },
  setItems: (context,data) =>{
    ApiRequest.get(data).then((res) => {
      let data = res.data.data;
      let meta = res.data.meta
      if (meta) {
        context.commit("setMeta", meta);
      } else {
        context.commit("setMeta", {});
      }
      context.commit("setItems", data);
      context.commit("setLoad", false);
    }).catch(()=>{
      context.commit("setLoad", false);
    });
  },
  showData: (context, payload) => {
    ApiRequest.get(payload).then((res) => {
      let data = res.data.data;
      let keys = Object.keys(data);
      let values = Object.values(data);
      let rows = [];
      for (let i = 0; i < keys.length; i++) {
        rows.push({ key: keys[i], value: values[i] });
      }
      context.commit("setItem", Object.assign(rows));
      context.commit("setLoad", false);
    })
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
