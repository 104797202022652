export const studentReportRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                name: "Student Report",
                path: "student-report",
                component: () => import("@/views/student-report/student_report_read.vue"),
                meta: {
                    title: "Student Report",
                    permission: "role_students",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "Student Report Show",
                path: "student-report/show/:id",
                component: () => import("@/views/student-report/student_report_show.vue"),
                meta: {
                    title: "Student Report Show",
                    permission: "role_students",
                    action: "read",
                    requiresAuth: true,
                },
            },
        ],
    };

export default {
    studentReportRoutes,
};
