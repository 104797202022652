<template>
  <div class="header-wrapper row m-0">
    <form
      class="form-inline search-full"
      action="#"
      method="get"
      :class="{ open: searchOpen }"
    >
      <div class="form-group w-100">
        <div class="Typeahead Typeahead--twitterUsers">
          <div class="u-posRelative">
            <input
              v-model="terms"
              class="demo-input Typeahead-input form-control-plaintext w-100"
              type="text"
              placeholder="Search Cuba .."
              name="q"
              title=""
              autofocus
            >
            <div
              class="spinner-border Typeahead-spinner"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <vue-feather
              class="close-search"
              type="x"
              @click="search_close()"
            />
          </div>
          <div
            v-if="menuItems.length"
            :class="searchResult ? 'Typeahead-menu is-open' : 'Typeahead-menu'"
          >
            <div
              v-for="(menuItem, index) in menuItems.slice(0, 8)"
              :key="index"
              class="ProfileCard u-cf"
            >
              <div class="ProfileCard-avatar header-search">
                <vue-feather :type="menuItem.icon" />
              </div>
              <div class="ProfileCard-details">
                <div class="ProfileCard-realName">
                  <span @click="removeFix()"><router-link
                    :to="{ path: menuItem.path }"
                    class="realname"
                  >{{ menuItem.title }}</router-link></span>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              searchResultEmpty ? 'Typeahead-menu is-open' : 'Typeahead-menu'
            "
          >
            <div class="tt-dataset tt-dataset-0">
              <div class="EmptyMessage">
                Your search turned up 0 results. Opps There are no result found.
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="left-header col horizontal-wrapper pl-0">
      <ul class="horizontal-menu">
        <li>
          <div class="header-logo-wrapper">
            <div class="logo-wrapper">
              <router-link to="/">
                <img
                  class="img-fluid"
                  src="../assets/images/logo/logo.png"
                  alt
                >
              </router-link>
            </div>
            <div
              class="toggle-sidebar"
              @click="toggle_sidebar"
            >
              <vue-feather
                id="sidebar-toggle"
                class="status_toggle middle sidebar-toggle"
                type="align-center"
              />
            </div>
          </div>
        </li>
        <li class="mega-menu outside">
          <a
            class="nav-link"
            href="javascript:void(0);"
          >
            <vue-feather type="layers" /><span>Bonus Ui</span>
          </a>
        </li>
        <li class="level-menu outside">
          <a
            class="nav-link"
            href="#"
          >
            <vue-feather type="inbox" />
            <span>Level Menu</span></a>
        </li>
      </ul>
    </div>
    <div class="nav-right col-8 pull-right right-header p-0">
      <ul class="nav-menus">
        <li>
          <span class="header-search"><vue-feather
            type="search"
            @click="search_open()"
          /></span>
        </li>
                <li class="onhover-dropdown">
                  <div class="notification-box">
                    <vue-feather type="bell" /><span class="badge badge-pill badge-secondary">4 </span>
                  </div>
                  <ul class="notification-dropdown onhover-show-div">
                    <li>
                      <vue-feather type="bell" />
                      <h6 class="f-18 mb-0">
                        Notitications
                      </h6>
                    </li>
                    <li>
                      <p>
                        <i class="fa fa-circle-o mr-3 font-primary" />Delivery
                        processing <span class="pull-right">10 min.</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <i class="fa fa-circle-o mr-3 font-success" />Order
                        Complete<span class="pull-right">1 hr</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <i class="fa fa-circle-o mr-3 font-info" />Tickets
                        Generated<span class="pull-right">3 hr</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <i class="fa fa-circle-o mr-3 font-danger" />Delivery
                        Complete<span class="pull-right">6 hr</span>
                      </p>
                    </li>
                    <li>
                      <a
                        class="btn btn-primary"
                        href="#"
                      >Check all notification</a>
                    </li>
                  </ul>
                </li>
<!--                <li class="onhover-dropdown">-->
<!--                  <div class="notification-box">-->
<!--                    <vue-feather type="star" />-->
<!--                  </div>-->
<!--                  <Bookmark />-->
<!--                </li>-->
        <li>
          <div class="mode">
            <i
              v-show="mixLayout === 'light-only'"
              class="fa fa-moon-o"
              @click="customizeMixLayout('dark-only')"
            />
            <i
              v-show="mixLayout === 'dark-only'"
              class="fa fa-lightbulb-o"
              @click="customizeMixLayout('light-only')"
            />
          </div>
        </li>
        <!--        <li class="cart-nav onhover-dropdown">-->
        <!--          <div class="cart-box">-->
        <!--            <vue-feather type="shopping-cart" />-->
        <!--            <span class="badge badge-pill badge-primary">2</span>-->
        <!--          </div>-->
        <!--          <ul class="cart-dropdown onhover-show-div">-->
        <!--            <li>-->
        <!--              <h6 class="mb-0 f-20">-->
        <!--                Shoping Bag-->
        <!--              </h6>-->
        <!--              <vue-feather type="shopping-cart" />-->
        <!--            </li>-->
        <!--            <li class="mt-0">-->
        <!--              <div class="media">-->
        <!--                <img-->
        <!--                  class="img-fluid rounded-circle mr-3 img-60"-->
        <!--                  src="../assets/images/ecommerce/01.jpg"-->
        <!--                  alt=""-->
        <!--                >-->
        <!--                <div class="media-body">-->
        <!--                  <span>V-Neck Shawl Collar Woman's Solid T-Shirt</span>-->
        <!--                  <p>Yellow(#fcb102)</p>-->
        <!--                  <div class="qty-box">-->
        <!--                    <div class="input-group">-->
        <!--                      <span class="input-group-prepend">-->
        <!--                        <button-->
        <!--                          class="btn quantity-left-minus"-->
        <!--                          type="button"-->
        <!--                          data-type="minus"-->
        <!--                          data-field=""-->
        <!--                        >-->
        <!--                          <vue-feather type="minus" />-->
        <!--                        </button>-->
        <!--                      </span>-->
        <!--                      <input-->
        <!--                        class="form-control input-number"-->
        <!--                        type="text"-->
        <!--                        name="quantity"-->
        <!--                        value="1"-->
        <!--                      ><span class="input-group-prepend">-->
        <!--                        <button-->
        <!--                          class="btn quantity-right-plus"-->
        <!--                          type="button"-->
        <!--                          data-type="plus"-->
        <!--                          data-field=""-->
        <!--                        >-->
        <!--                          <vue-feather type="plus" /></button></span>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <h6 class="text-right text-muted">-->
        <!--                    $299.00-->
        <!--                  </h6>-->
        <!--                </div>-->
        <!--                <div class="close-circle">-->
        <!--                  <a href="#"><vue-feather type="x" /></a>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </li>-->
        <!--            <li class="mt-0">-->
        <!--              <div class="media">-->
        <!--                <img-->
        <!--                  class="img-fluid rounded-circle mr-3 img-60"-->
        <!--                  src="../assets/images/ecommerce/03.jpg"-->
        <!--                  alt=""-->
        <!--                >-->
        <!--                <div class="media-body">-->
        <!--                  <span>V-Neck Shawl Collar Woman's Solid T-Shirt</span>-->
        <!--                  <p>Yellow(#fcb102)</p>-->
        <!--                  <div class="qty-box">-->
        <!--                    <div class="input-group">-->
        <!--                      <span class="input-group-prepend">-->
        <!--                        <button-->
        <!--                          class="btn quantity-left-minus"-->
        <!--                          type="button"-->
        <!--                          data-type="minus"-->
        <!--                          data-field=""-->
        <!--                        >-->
        <!--                          <vue-feather type="minus" /></button></span>-->
        <!--                      <input-->
        <!--                        class="form-control input-number"-->
        <!--                        type="text"-->
        <!--                        name="quantity"-->
        <!--                        value="1"-->
        <!--                      ><span class="input-group-prepend">-->
        <!--                        <button-->
        <!--                          class="btn quantity-right-plus"-->
        <!--                          type="button"-->
        <!--                          data-type="plus"-->
        <!--                          data-field=""-->
        <!--                        >-->
        <!--                          <vue-feather type="plus" /></button></span>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <h6 class="text-right text-muted">-->
        <!--                    $299.00-->
        <!--                  </h6>-->
        <!--                </div>-->
        <!--                <div class="close-circle">-->
        <!--                  <a href="#"><vue-feather type="x" /></a>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <div class="total">-->
        <!--                <h6 class="mb-2 mt-0 text-muted">-->
        <!--                  Order Total : <span class="f-right f-20">$598.00</span>-->
        <!--                </h6>-->
        <!--              </div>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              &lt;!&ndash;              <router-link&ndash;&gt;-->
        <!--              &lt;!&ndash;                  class="btn btn-block btn-primary view-cart"&ndash;&gt;-->
        <!--              &lt;!&ndash;                  :to="'/ecommerce/cart'"&ndash;&gt;-->
        <!--              &lt;!&ndash;              >&ndash;&gt;-->
        <!--              &lt;!&ndash;                Go to shoping bag&ndash;&gt;-->
        <!--              &lt;!&ndash;              </router-link>&ndash;&gt;-->
        <!--              &lt;!&ndash;              <router-link&ndash;&gt;-->
        <!--              &lt;!&ndash;                  :to="'/ecommerce/checkout'"&ndash;&gt;-->
        <!--              &lt;!&ndash;                  class="btn btn-block btn-secondary view-cart"&ndash;&gt;-->
        <!--              &lt;!&ndash;              >&ndash;&gt;-->
        <!--              &lt;!&ndash;                Checkout&ndash;&gt;-->
        <!--              &lt;!&ndash;              </router-link>&ndash;&gt;-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </li>-->
        <!--        <li class="onhover-dropdown">-->
        <!--          <vue-feather type="message-square" />-->
        <!--          <ul class="chat-dropdown onhover-show-div">-->
        <!--            <li>-->
        <!--              <vue-feather type="message-square" />-->
        <!--              <h6 class="f-18 mb-0">-->
        <!--                Message Box-->
        <!--              </h6>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <div class="media">-->
        <!--                <img-->
        <!--                  class="img-fluid rounded-circle mr-3"-->
        <!--                  src="../assets/images/user/1.jpg"-->
        <!--                  alt=""-->
        <!--                >-->
        <!--                <div class="status-circle online" />-->
        <!--                <div class="media-body">-->
        <!--                  <span>Erica Hughes</span>-->
        <!--                  <p>Lorem Ipsum is simply dummy...</p>-->
        <!--                </div>-->
        <!--                <p class="f-12 font-success">-->
        <!--                  58 mins ago-->
        <!--                </p>-->
        <!--              </div>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <div class="media">-->
        <!--                <img-->
        <!--                  class="img-fluid rounded-circle mr-3"-->
        <!--                  src="../assets/images/user/2.jpg"-->
        <!--                  alt=""-->
        <!--                >-->
        <!--                <div class="status-circle online" />-->
        <!--                <div class="media-body">-->
        <!--                  <span>Kori Thomas</span>-->
        <!--                  <p>Lorem Ipsum is simply dummy...</p>-->
        <!--                </div>-->
        <!--                <p class="f-12 font-success">-->
        <!--                  1 hr ago-->
        <!--                </p>-->
        <!--              </div>-->
        <!--            </li>-->
        <!--            <li>-->
        <!--              <div class="media">-->
        <!--                <img-->
        <!--                  class="img-fluid rounded-circle mr-3"-->
        <!--                  src="../assets/images/user/4.jpg"-->
        <!--                  alt=""-->
        <!--                >-->
        <!--                <div class="status-circle offline" />-->
        <!--                <div class="media-body">-->
        <!--                  <span>Ain Chavez</span>-->
        <!--                  <p>Lorem Ipsum is simply dummy...</p>-->
        <!--                </div>-->
        <!--                <p class="f-12 font-danger">-->
        <!--                  32 mins ago-->
        <!--                </p>-->
        <!--              </div>-->
        <!--            </li>-->
        <!--            <li class="text-center">-->
        <!--              <a-->
        <!--                class="btn btn-primary"-->
        <!--                href="#"-->
        <!--              >View All </a>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </li>-->
        <li class="maximize">
          <a
            class="text-dark"
            href="javascript:void(0)"
            @click="toggle_fullscreen()"
          >
            <vue-feather type="maximize" /></a>
        </li>
        <li class="profile-nav onhover-dropdown p-0 mr-0">
          <div class="media profile-media d-flex">
            <img
              class="b-r-10"
              src="../assets/images/dashboard/profile.jpg"
              alt=""
            >
            <div class="media-body">
              <span>{{ fullName }}</span>
              <p class="mb-0 font-roboto">
                {{ role }} <i class="middle fa fa-angle-down" />
              </p>
            </div>
          </div>
          <ul class="profile-dropdown onhover-show-div">
            <li>
              <router-link to="/profile">
                <vue-feather type="user" /><span>Profile</span>
              </router-link>
            </li>
            <li>
              <router-link to="/profile">
                <vue-feather type="settings" /><span>Settings</span>
              </router-link>
            </li>
            <li @click="logOut">
              <a><vue-feather type="log-in" /><span>Log out</span></a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!--    <div class="result-template" type="text/x-handlebars-template">-->
    <!--      <div class="ProfileCard u-cf">-->
    <!--        <div class="ProfileCard-avatar">-->
    <!--          <svg-->
    <!--              xmlns="http://www.w3.org/2000/svg"-->
    <!--              width="24"-->
    <!--              height="24"-->
    <!--              viewBox="0 0 24 24"-->
    <!--              fill="none"-->
    <!--              stroke="currentColor"-->
    <!--              stroke-width="2"-->
    <!--              stroke-linecap="round"-->
    <!--              stroke-linejoin="round"-->
    <!--              class="feather feather-airplay m-0"-->
    <!--          >-->
    <!--            <path-->
    <!--                d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"-->
    <!--            ></path>-->
    <!--            <polygon points="12 15 17 21 7 21 12 15"></polygon>-->
    <!--          </svg>-->
    <!--        </div>-->
    <!--        <div class="ProfileCard-details">-->
    <!--          <div class="ProfileCard-realName">-->
    <!--            name-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="empty-template" type="text/x-handlebars-template">-->
    <!--      <div class="EmptyMessage">-->
    <!--        Your search turned up 0 results. This most likely means the backend is down, yikes!-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<script>
var body = document.getElementsByTagName("body")[0];
import { mapState } from "vuex";
import ApiRequest from  "@/helpers/request"
// import Bookmark  from "./bookmark";
import Auth from "../auth/js/index.js"
export default {
  name: "HeaderPage",
  components: {
    // Bookmark,
  },
  data() {
    return {
      terms: "",
      searchOpen: false,
      searchResult: false,
      searchResultEmpty: false,
      close_sidebar_var: false,
      clicked: false,
      mobile_toggle: false,
      mobile_search: false,
      openbonusUI: false,
      openLevelmenu: false,
      openlanguage: false,
      mobile_accordian: false,
      mixLayout: "light-only",
      fullName: '',
      role: ''
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
    }),
  },
  watch: {
    "$i18n.locale"(to, from) {
      if (from !== to) {
        this.$router.go(this.$route.path);
      }
    },
    menuItems: function () {
      this.terms ? this.addFix() : this.removeFix();
      if (!this.menuItems.length) this.searchResultEmpty = true;
      else this.searchResultEmpty = false;
    },
  },
  created() {
    this.fullName = JSON.parse(localStorage.getItem('userinfo')).displayName
    this.role = JSON.parse(localStorage.getItem('userinfo')).role
  },
  methods: {
    logOut(){
      ApiRequest.post('logout')
      Auth.logOut()
    },
    toggle_sidebar() {
      this.$store.dispatch("menu/opensidebar");
    },
    setNavActive(item) {
      this.$store.dispatch("menu/setBonusNavActive", item);
    },
    openlangpicker() {
      this.openlanguage = !this.openlanguage;
    },
    openlevelmenu() {
      this.openLevelmenu = !this.openLevelmenu;
    },
    openmegamenu() {
      this.openbonusUI = !this.openbonusUI;
    },
    closeBonusUI() {
      this.openbonusUI = false;
    },
    search_open() {
      this.searchOpen = true;
    },
    search_close() {
      this.searchOpen = false;
    },
    searchterm: function () {
      this.$store.dispatch("menu/searchTerm", this.terms);
    },
    changeLocale(locale) {
      this.setLang(locale);
    },
    mobileaccordian() {
      this.mobile_accordian = !this.mobile_accordian;
    },
    addFix() {
      body.classList.add("offcanvas");
      this.searchResult = true;
    },
    removeFix() {
      body.classList.remove("offcanvas");
      this.searchResult = false;
      this.terms = "";
    },
    toggle_fullscreen() {
      if (
          (document.fullScreenElement && document.fullScreenElement !== null) ||
          (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
              Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    customizeMixLayout(val) {
      this.mixLayout = val;
      this.$store.dispatch("layout/setLayout", val);
    },
  },
};
</script>
