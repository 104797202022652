export const courseCategoryRoutes =
    {
        path: "/",
        component: () => import("@/components/transparentPage"),
        requiresAuth: true,
        children: [
            {
                path: '/course-category/:id',
                name: "Course by Category",
                component: () => import("@/views/course/course_by_category.vue"),
                meta: {
                    title: "Course category",
                    permission: "role_course_categories",
                    action: "read",
                    requiresAuth: true,
                }
            },
            {
                path: "course-category",
                name: "Categories",
                component: () => import("@/views/category/course_category_read.vue"),
                meta: {
                    title: "Course categories",
                    permission: "role_course_categories",
                    action: "read",
                    requiresAuth: true,
                },
            },
            {
                name: "course category update",
                path: "/course-category/update/:id",
                component: () => import("@/views/category/course_category_update.vue"),
                meta: {
                    title: "Course category update",
                    permission: "role_course_categories",
                    action: "update",
                    requiresAuth: true,
                }
            },
            {
                name: "course category create",
                path: "/course-category/create/",
                component: () => import("@/views/category/course_category_create.vue"),
                meta: {
                    title: "Course category create",
                    permission: "role_course_categories",
                    action: "create",
                    requiresAuth: true,
                }
            },
        ],
    };

export default {
    courseCategoryRoutes,
};
